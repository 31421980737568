import { http } from '../core';

class SurveyService {
  async get(clientId: any, leadId: any, surveyId: any) {
    const { data } = await http.request({
      method: 'GET',
      url: `/survey/${surveyId}/`
    });

    data.leadId = leadId;
    data.clientId = clientId;

    return data;
  }

  async addComments(
    survey: any,
    answers: number[],
    comment: string,
    responseId?: string
  ) {
    const { data } = await http.request({
      method: 'PATCH',
      url: `/survey/response/${responseId}/`,
      data: {
        comment,
        answers,
        client: survey.clientId,
        lead: survey.leadId,
        survey: survey.id
      }
    });

    return data;
  }

  async logResponse(survey: any, answers: number[], responseId?: string) {
    const { data } = await http.request({
      method: responseId ? 'PATCH' : 'POST',
      url: `/survey/response/${responseId ? `${responseId}/` : ''}`,
      data: {
        client: survey.clientId,
        lead: survey.leadId,
        survey: survey.id,
        answers
      }
    });

    return data;
  }
}

export default new SurveyService();
