import React, { useContext, useCallback, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { surveyService } from '../services';
import Rating from './Rating';
import LaddaButton from './LaddaButton';

const SurveyQuestion: React.FC = () => {
  const { state, dispatch, animate } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  const q = state.survey?.survey_questions?.[state.question];
  const setAnswer = useCallback(
    (val: number) => {
      const answers = state.answers;
      answers[state.question] = val;
      dispatch({ answers });
    },
    [state.answers, state.question, dispatch]
  );

  const updateAnswers = useCallback(async () => {
    try {
      if (!state.answers[state.question]) {
        return dispatch({ error: 'Please select a rating.' });
      }

      setUpdating(true);
      const { id } = await surveyService.logResponse(
        state.survey,
        state.answers,
        state.responseId
      );
      animate('slideRightReturn');
      dispatch({ question: state.question + 1, error: null, responseId: id });
      setUpdating(false);
    } catch (e) {
      console.error(e);
      setUpdating(false);
    }
  }, [
    state.survey,
    state.answers,
    state.responseId,
    state.question,
    dispatch,
    animate
  ]);

  return (
    <>
      <h1>How did we do at {state.widget?.name}?</h1>
      <p className="subtext">{q?.text}</p>
      <Rating
        onChange={setAnswer}
        initialRating={state.answers[state.question]}
        stop={q?.choices.length}
      />
      <LaddaButton
        loading={updating}
        disabled={!state.answers[state.question]}
        onClick={updateAnswers}
      >
        Next
      </LaddaButton>
      {state.error && <p className="error">{state.error}</p>}
    </>
  );
};

export default SurveyQuestion;
