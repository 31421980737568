import React, { createContext, useReducer, Dispatch, useCallback } from 'react';

export interface AppState {
  survey?: any;
  widget?: any;
  loading: boolean;
  error?: string;
  clientId?: any;
  leadId?: any;
  question: number;
  answers: number[];
  responseId?: string;
}

export interface AppContextProps {
  state: AppState;
  dispatch: Dispatch<any>;
  animate: (animation: string) => void;
}

export const AppContext = createContext({} as AppContextProps);

let reducer = (state: AppState, value: any) => {
  const s = { ...state };
  return Object.assign(s, value || {});
};

export const AppContextProvider = (props: any) => {
  const init = props.initialState || {};
  const initialState: AppState = Object.assign(
    {
      loading: true,
      question: 0,
      answers: []
    },
    init
  );

  let [state, dispatch] = useReducer(reducer, initialState);

  const animate = useCallback((animation: string) => {
    const element = document.querySelector('.survey-container');
    if (element) {
      element.classList.add('magictime', animation);
      setTimeout(() => element.classList.remove('magictime', animation), 500);
    }
  }, []);

  const value = {
    state,
    dispatch,
    animate
  } as AppContextProps;

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;
