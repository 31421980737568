import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import FacebookReviewButton from './FacebookReviewButton';

const SurveyQuestion: React.FC = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <h1>Thank You!</h1>
      <p className="subtext">
        Your feedback helps us get better at {state.widget?.name}.
      </p>
      {state.widget?.fbpage && (
        <FacebookReviewButton pageId={state.widget.fbpage} />
      )}
    </>
  );
};

export default SurveyQuestion;
