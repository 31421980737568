import React from 'react';
import ReactRating from 'react-rating';
import starOutline from '../resources/star-outline.svg';
import star from '../resources/star.svg';

export interface RatingProps {
  onChange?: (value: number) => any;
  stop?: number;
  initialRating?: number;
}

const Rating: React.FC<RatingProps> = ({
  onChange,
  initialRating,
  stop = 5
}) => {
  return (
    <ReactRating
      onChange={onChange}
      initialRating={initialRating}
      stop={stop}
      emptySymbol={<img src={starOutline} className="rating-icon" alt="star" />}
      fullSymbol={<img src={star} className="rating-icon" alt="star" />}
    />
  );
};

export default Rating;
