import React from 'react';

const Page404: React.FC = () => {
  return (
    <div className="four-oh-four">
      <span>Page Not Found</span>
      <a className="go-back" href="https://www.tecobi.com/">
        Go Back
      </a>
    </div>
  );
};

export default Page404;
