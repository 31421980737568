import React from 'react';
import facebookLogo from '../resources/f_logo.png';

export interface FacebookReviewButtonProps {
  pageId: string;
}

const FacebookReviewButton: React.FC<FacebookReviewButtonProps> = ({
  pageId
}) => {
  return (
    <a
      className="facebook-button"
      href={`https://www.facebook.com/pg/${pageId}/reviews/`}
    >
      <span>Review us on Facebook</span>
      <img src={facebookLogo} className="facebook-logo" alt="Facebook Logo" />
    </a>
  );
};

export default FacebookReviewButton;
