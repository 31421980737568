import React, { useCallback, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../context/AppContext';
import { surveyService, widgetService } from '../services';
import { SurveyQuestion, SurveyComplete, SurveyComments } from '../components';
import css from 'classnames';

export interface SurveyParams {
  clientId: string;
  leadId: string;
  surveyId: string;
}

const Survey: React.FC = () => {
  const { state, dispatch, animate } = useContext(AppContext);
  const { clientId, leadId, surveyId } = useParams() as SurveyParams;

  const loadSurvey = useCallback(async () => {
    try {
      const [survey, widget] = await Promise.all([
        surveyService.get(clientId, leadId, surveyId),
        widgetService.get(clientId)
      ]);

      dispatch({
        survey,
        widget,
        clientId,
        leadId,
        question: 0,
        loading: false,
        answers: []
      });
    } catch (e) {
      console.error(e);
      window.location.href = '/';
      dispatch({
        loading: false,
        survey: null,
        widget: null,
        clientId: null,
        leadId: null,
        question: 0,
        answers: []
      });
    }
  }, [clientId, leadId, surveyId, dispatch]);

  useEffect(() => {
    loadSurvey();
  }, [loadSurvey]);

  const goBack = useCallback(() => {
    animate('slideLeftReturn');
    dispatch({ question: Math.max(state.question - 1, 0) });
  }, [state.question, dispatch, animate]);

  const isComplete = state.survey?.is_complete;
  const showComments =
    !isComplete && state.question >= state.survey?.survey_questions?.length;

  let step: any;

  if (isComplete) {
    step = <SurveyComplete />;
  } else if (showComments) {
    step = <SurveyComments />;
  } else {
    step = <SurveyQuestion />;
  }

  return (
    <div className="survey">
      {!state.loading && (
        <div className="survey-container magictime puffIn">
          <div
            className={css('back-button', {
              visible: state.question > 0 && !isComplete
            })}
            onClick={goBack}
          >
            <span className="chevron left" />
            <label>Back</label>
          </div>
          {step}
        </div>
      )}
    </div>
  );
};

export default Survey;
