import React, { useContext, useState, useCallback } from 'react';
import { AppContext } from '../context/AppContext';
import { surveyService } from '../services';
import { http } from '../core';
import { LaddaButton } from '.';

const SurveyComments: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [comments, setComments] = useState('');
  const [updating, setUpdating] = useState(false);

  const addComments = useCallback(async () => {
    try {
      setUpdating(true);
      await surveyService.addComments(
        state.survey,
        state.answers,
        comments,
        state.responseId
      );
      const survey = state.survey;
      survey.is_complete = true;
      dispatch({
        survey
      });
    } catch (e) {
      dispatch({
        error: http.getErrorMessage(e)
      });
    } finally {
      setUpdating(false);
    }
  }, [comments, state.survey, state.responseId, state.answers, dispatch]);

  return (
    <>
      <h1>Comments</h1>
      <p className="subtext">
        Have any comments for us at {state.widget?.name}? Let us know!
      </p>
      <textarea
        placeholder="Comments (optional)"
        value={comments}
        onChange={(e: any) => setComments(e.target.value)}
      />
      <LaddaButton loading={updating} onClick={addComments}>
        Next
      </LaddaButton>
    </>
  );
};

export default SurveyComments;
