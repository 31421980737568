import React, { useRef, useCallback, useEffect } from 'react';
import css from 'classnames';
import { LaddaButton, create } from 'ladda';
import 'ladda/dist/ladda-themeless.min.css';

export interface LaddaButtonProps extends React.ComponentProps<any> {
  disabled?: boolean;
  loading: boolean;
  loadingStyle?: string;
}

const TLaddaButton: React.FC<LaddaButtonProps> = ({
  loading,
  loadingStyle = 'expand-right',
  disabled,
  children,
  ...rest
}) => {
  const l = useRef<LaddaButton>();
  const btn = useRef<any>();

  const setup = useCallback(() => {
    const existing = l.current;
    const remove = () => {
      if (existing) {
        existing.remove();
      }
    };

    if (btn.current) {
      l.current = create(btn.current);
    }

    return remove;
  }, [btn]);

  useEffect(() => setup(), [setup]);

  useEffect(() => {
    if (loading) {
      l.current?.start();
    } else {
      l.current?.stop();
    }
  }, [loading]);

  return (
    <button
      ref={btn}
      data-style={loadingStyle}
      className={css({ disabled })}
      {...rest}
    >
      {children}
    </button>
  );
};

export default TLaddaButton;
