import axios, { AxiosRequestConfig } from 'axios';
import appConfig from '../config.json';

class HTTP {
  get FORM_HEADER() {
    return {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
  }

  async request(options: AxiosRequestConfig) {
    const req: AxiosRequestConfig = Object.assign(
      {
        baseURL: appConfig.tecobi.api.baseUrl
      },
      options
    );

    return await axios(req);
  }

  getErrorMessage(e: any) {
    let res = e.response || {};
    if (res.status === 400) {
      return Object.values(e.response.data)[0] as string;
    } else if (e.message) {
      return e.message;
    }

    return 'An unknown error has occurred. Please contact support.';
  }
}

export default new HTTP();
