import { http } from '../core';

class WidgetService {
  async get(clientId: string) {
    const { data } = await http.request({
      method: 'GET',
      url: `/client-widgets/${clientId}/`
    });

    return data;
  }
}

export default new WidgetService();
