import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './styles/app.css';
import 'magic.css/dist/magic.css';
import { AppContextProvider } from './context/AppContext';
import { Page404, Survey } from './pages';

const App = () => {
  return (
    <AppContextProvider>
      <Router>
        <Switch>
          <Route
            exact
            path="/:clientId/:leadId/:surveyId/"
            component={Survey}
          />
          <Route path="*" component={Page404} />
        </Switch>
      </Router>
    </AppContextProvider>
  );
};

export default App;
